<template>
    <b-modal :title="$t('general.buyer')" v-model="modalActive" no-close-on-backdrop>
        <template #modal-header>
            <b-tabs-header :tabs="titles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
        </template>
        <template #default>
            <div>
                <validation-observer ref="validation">
                    <div v-if="activeTab === 0" :title="$t('general.buyer')">
                        <div class="row my-2 secondary-text">
                            <div class="col mx-1 buyerNotActive" :class="{ 'buyerActive': activeBuyer === Buyer.TaxPayer}" @click="changeActiveState(Buyer.TaxPayer)">{{$t('keys.buyers.tax_payer')}}</div>
                            <div class="col buyerNotActive" :class="{ 'buyerActive': activeBuyer === Buyer.NotTaxPayer}" @click="changeActiveState(Buyer.NotTaxPayer)">{{$t('keys.buyers.not_tax_payer')}}</div>
                            <div class="col mx-1 buyerNotActive" :class="{ 'buyerActive': activeBuyer === Buyer.Foreign}" @click="changeActiveState(Buyer.Foreign)">{{$t('keys.buyers.foreign')}}</div>
                        </div>
                        <b-form-group :label="$t('general.tax_number')">
                            <b-row class="row p-0 m-0 w-100">
                                <div class="col-2 p-0">
                                    <b-form-input  v-model="prefix" rules="required|length:2" :disabled="activeBuyer !== Buyer.Foreign"/>
                                </div>
                                <div class="col-8">
                                    <validation-provider #default="{ errors }" :name="$t('general.tax_number')" :rules="TAXValidationRules">
                                        <b-form-input ref="taxNumber" autofocus @input="checkTAXMatch" v-model="addObject.tax_number"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </div>

                                <div class="col-2 p-0 w-100">
                                    <b-button class="w-100" @click="buyerTaxNumberChanged()" variant="primary"><feather-icon icon="SearchIcon"/>
                                    </b-button>
                                </div>
                            </b-row>

                        </b-form-group>
                        <b-form-group :label="$t('form_data.name')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                                <b-form-input v-model="addObject.name"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.address')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.address')" rules="required">
                                <b-form-input v-model="addObject.address"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.country')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.country')" rules="required">
                                <b-form-input v-model="addObject.country" :disabled="activeBuyer !== Buyer.Foreign"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div >
                    <div v-if="activeTab === 1" :title="$t('keys.advanced')">

                        <b-form-group :label="$t('keys.predefined_discount')">
                            <v-select v-model="addObject.predefined_discount_id" appendToBody label="name" :options="predefinedDiscounts" :reduce="item => item.id"/>
                        </b-form-group>
                        <b-form-group :label="$t('keys.buyers.days_until_deadline')">
                            <validation-provider #default="{ errors }" :name="$t('keys.buyers.days_until_deadline')" rules="is_number|min_value:0">
                                <b-form-input v-model.number="addObject.payment_deadline"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('keys.buyers.additional_info')">
                            <validation-provider #default="{ errors }" :name="$t('keys.buyers.additional_info')">
                                <b-form-input v-model="addObject.additional_info"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider></b-form-group>
                    </div>
                    <div v-if="activeTab === 2" :title="$t('form_data.contact_person')">

                        <b-form-group :label="$t('form_data.person_name')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.person_name')" :rules="getContactNameRules">
                                <b-form-input ref="name" autofocus v-model="addObject.contact.name"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.email')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.email')" :rules="getContactMailRules">
                                <b-form-input v-model="addObject.contact.email"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('form_data.phone')" style="position: relative;">
                            <span style="position: absolute; right: 10px; margin-top: 10px;">
                                <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" color="#72a5d8" />
                                <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                                    {{$t('general.supported_formats')}}:<br>
                                    <span v-html="$supported_phone_formats"/>
                                </b-tooltip>
                            </span>
                            <validation-provider #default="{ errors }" :name="$t('form_data.phone')" :rules="getContactPhoneRules">
                                <b-form-input v-model="addObject.contact.phone"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </validation-observer>
            </div>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BTooltip, BRow} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, numeric } from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import { Buyer } from '@/libs/enums/Buyer'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'
    import i18n from '@/i18n/i18n'

    export default {
        components: {
            BTabsHeader,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BTooltip,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            BRow
        },
        props: {
            predefinedDiscounts: {
                type: Array
            }
        },
        data() {
            return {
                activeTab: 0,
                prefix: 'SI',
                activeBuyer: 0,
                modalActive: false,
                addObject: {
                    name: '',
                    address: '',
                    country: 'Slovenija',
                    tax_number: '',
                    type: '',
                    predefined_discount_id: '',
                    contact: {name: '', email: '', phone: ''},
                    additional_info: '',
                    payment_deadline: '',
                    additional_options: {}
                },
                required,
                numeric,
                editing: false,
                foundBuyer: [],
                found: false
            }
        },
        methods: {
            handleClickTab(index) {
                this.activeTab = index
                if (index === 0) {
                    this.focusTax()
                } if (index === 2) {
                    this.focusName()
                }
            },
            changeActiveState(state) {
                this.activeBuyer = state
                this.addObject.type = this.activeBuyer
                this.addObject.tax_number = ''
                this.addObject.address = ''
                this.addObject.name = ''

                if (state !== Buyer.Foreign) {
                    this.addObject.country = i18n.t('menu.locales.slovenia')
                    if (this.activeBuyer === Buyer.TaxPayer) {
                        this.prefix = 'SI'
                    } else if (this.activeBuyer === Buyer.NotTaxPayer) {
                        this.prefix = ''
                    }
                } else {
                    this.addObject.country = ''
                    this.prefix = ''
                }

            },
            async focusTax() {
                await this.$nextTick()
                this.$refs.taxNumber.focus()
            },
            async focusName() {
                await this.$nextTick()
                this.$refs.name.focus()
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    
                    if (this.addObject.predefined_discount_id === this.NullPrimitiveObjectId) {
                        this.addObject.predefined_discount_id = null
                    }
                    
                    this.activeBuyer = this.addObject.type
                    
                    const regex = /[A-Za-z]*/
                    
                    const result = regex.exec(this.addObject.tax_number)
                    if (result.length > 0) {
                        this.prefix = result[0]
                        this.addObject.tax_number = this.addObject.tax_number.replace(result[0], '')
                    } else {
                        this.prefix = ''
                    }

                    this.editing = true
                } else {
                    this.editing = false
                    this.prefix = 'SI'
                    this.activeBuyer = 0
                    this.addObject = {
                        name: '',
                        address: '',
                        country: i18n.t('menu.locales.slovenia'),
                        tax_number: '',
                        predefined_discount_id: '',
                        contact: {name: '', email: '', phone: ''},
                        additional_info: '',
                        payment_deadline: '',
                        additional_options: {}
                    }
                }
                this.modalActive = true
            },
            async validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async buyerTaxNumberChanged() {
                try {
                    this.addObject.tax_number = this.addObject.tax_number.trim()
                    
                    const response = await this.$http.post('/api/client/v1/tax_numbers/full', {'tax_number': this.prefix.toUpperCase() + this.addObject.tax_number})
                    this.foundBuyer = response.data ?? []
                    this.addObject.name = this.foundBuyer.name
                    this.addObject.address = this.foundBuyer.address
                    this.addObject.country = this.foundBuyer.country
                    this.found = true
                } catch (err) {
                    this.found = false
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.tax_number'))
                }
            },
            checkTAXMatch() {
                if (this.found) {
                    this.addObject.name = ''
                    this.addObject.address = ''
                    this.addObject.country = ''
                }
            },
            async add() {
                try {
                    this.addObject.payment_deadline = parseInt(this.addObject.payment_deadline, 10)
                    this.addObject.tax_number = this.prefix.trim() + this.addObject.tax_number.trim()
                    this.addObject.type = this.activeBuyer
                 
                    const response = await this.$http.post('/api/client/v1/buyers/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        address: '',
                        country: i18n.t('menu.locales.slovenia'),
                        tax_number: '',
                        type: '',
                        predefined_discount_id: '',
                        contact: {name: '', email: '', phone: ''},
                        additional_info: '',
                        payment_deadline: '',
                        additional_options: {}
                    }
                    this.tax = ''
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'pin already exists')) {
                        this.$printSuccess(this.$t('print.warning.pin_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'tax number already exists')) {
                        this.$printSuccess(this.$t('print.warning.tax_number_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                }
            },
            async edit() {
                try {
                    this.addObject.tax_number = this.prefix.trim() + this.addObject.tax_number.trim()
                    this.addObject.type = this.activeBuyer
                    
                    await this.$http.put(`/api/client/v1/buyers/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        address: '',
                        country: i18n.t('menu.locales.slovenia'),
                        tax_number: '',
                        predefined_discount_id: '',
                        contact: {name: '', email: '', phone: ''},
                        additional_info: '',
                        payment_deadline: '',
                        additional_options: {}
                    }
                    this.tax = ''
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'pin already exists')) {
                        this.$printSuccess(this.$t('print.warning.pin_already_exists'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'tax number already exists')) {
                        this.$printSuccess(this.$t('print.warning.tax_number_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                }
            }
        },
        computed:{
            titles() {
                return [{title: this.$t('general.buyer')}, {title: this.$t('keys.advanced')}, {title: this.$t('keys.contact')}]
            },
            getContactNameRules() {
                if (this.addObject.contact.email !== '' || this.addObject.contact.phone !== '') {
                    return 'required'
                } else {
                    return ''
                }
            },
            getContactMailRules() {
                if (this.addObject.contact.name !== '' || this.addObject.contact.phone !== '') {
                    return 'email|required'
                } else {
                    return ''
                }
            },
            getContactPhoneRules() {
                if (this.addObject.contact.name !== '' || this.addObject.contact.email !== '') {
                    return 'phone_number|required'
                } else {
                    return ''
                }
            },
            Buyer() {
                return Buyer
            },
            TAXValidationRules() {
                if (this.activeBuyer !== Buyer.Foreign) {
                    return 'required|tax_slovenian|is_number'
                } else {
                    return 'required|is_number|tax'
                }
            }
        }
    }
</script>
<style scoped>
.buyerNotActive{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #82868b;
  padding: 10px;
  text-align: center;
  border-radius: 18px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.buyerActive{
  background: #98a89a !important;
}


</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
